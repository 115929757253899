import React from "react"
import { graphql } from "gatsby"

export const data = graphql`
  query getBildquellen {
    sources: contentfulBildquellenverzeichnis {
      imageSources: bildquellen
    }
  }
`

const BildquellenverzeichnisPage = ({ data }) => {
  const {
    sources: { imageSources },
  } = data
  return (
    <div>
      <h2>Bildquellenverzeichnis</h2>
      {imageSources.map((src, index) => (
        <p key={index}>{src}</p>
      ))}
    </div>
  )
}

export default BildquellenverzeichnisPage
